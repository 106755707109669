var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "__main_card_container"
  }, [_c('div', {
    staticClass: "_main_card_header"
  }, [_c('div', {
    staticClass: "__header_inner admin_header_padding"
  }, [_c('div', {
    staticClass: "desktop-nav-sender",
    staticStyle: {
      "min-width": "fit-content",
      "padding-right": "10px"
    }
  }, [_c('button', {
    staticClass: "btn",
    class: [_vm.$route.path.includes('sender_ids') ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/pending').catch(err => {});
      }
    }
  }, [_vm._v(" Sender IDs ")]), !_vm.disallowedUsers ? _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/PendingReseller' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/PendingReseller').catch(err => {});
      }
    }
  }, [_vm._v(" Reseller Sender IDs ")]) : _vm._e()]), _c('div', {
    staticClass: "side_nav_wrapper",
    staticStyle: {
      "overflow-x": "auto",
      "min-height": "2rem"
    }
  }, [_c('div', {
    staticClass: "__side_nav"
  }, [_c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/pending' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/pending').catch(err => {});
      }
    }
  }, [_vm._v(" Pending " + _vm._s(`(${_vm.pending ? _vm.pending : 0})`) + " ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/approved' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/approved').catch(err => {});
      }
    }
  }, [_vm._v(" Approved " + _vm._s(`(${_vm.approved ? _vm.approved : 0})`) + " ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/declined' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/declined').catch(err => {});
      }
    }
  }, [_vm._v(" Declined " + _vm._s(`(${_vm.declined ? _vm.declined : 0})`) + " ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/pendingUserVerification' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/pendingUserVerification').catch(err => {});
      }
    }
  }, [_vm._v(" Pending user verification " + _vm._s(`(${_vm.pendingUserApproval ? _vm.pendingUserApproval : 0})`) + " ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/autoApproved' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/autoApproved').catch(err => {});
      }
    }
  }, [_vm._v(" Auto Approved " + _vm._s(`(${_vm.autoApproved ? _vm.autoApproved : 0})`) + " ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/sender_ids/ReservedSenderIDs' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/sender_ids/ReservedSenderIDs').catch(err => {});
      }
    }
  }, [_vm._v(" Reserved Sender IDs " + _vm._s(`(${_vm.reserved ? _vm.reserved : 0})`) + " ")])])])])]), _c('div', {
    staticClass: "main_card_body"
  }, [_c('router-view')], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };