import store from "@/state/store.js";
export default {
  components: {},
  data() {
    return {
      approvedCount: 0,
      pendingCount: 0,
      declinedCount: 0,
      pendingUserApprovalCount: 0,
      autoApprovedCount: 0,
      ReservedSenderIDs: 0
    };
  },
  computed: {
    getPageTitle() {
      const currentPage = this.$route.path;
      if (currentPage == "/admin/sender_ids/pending") {
        return "Sender ID Requests";
      }
      if (currentPage == "/admin/sender_ids/approved") {
        return "Approved Sender IDs";
      }
      if (currentPage == "/admin/sender_ids/declined") {
        return "Declined Sender IDs";
      }
      if (currentPage == "/admin/sender_ids/pendingUserVerification") {
        return "Holding Sender IDs";
      }
      if (currentPage == "/admin/sender_ids/autoApproved") {
        return "Auto Approved Sender IDs";
      }
      if (currentPage == "/admin/sender_ids/ReservedSenderIDs") {
        return "Reserved Sender IDs";
      }
    },
    pending() {
      return store.getters["senderid/pendingSenderIDs"];
    },
    approved() {
      return store.getters["senderid/approvedSenderIDs"];
    },
    autoApproved() {
      return store.getters["senderid/autoApprovedSenderIDs"];
    },
    pendingUserApproval() {
      return store.getters["senderid/holdingSenderIDs"];
    },
    declined() {
      return store.getters["senderid/declinedSenderIDS"];
    },
    reserved() {
      return store.getters["senderid/reservedSenderIds"];
    },
    disallowedUsers() {
      return this.$store.getters["auth/disallowedUsers"];
    }
  },
  mounted() {
    store.dispatch("senderid/adminSenderIDs", {
      status: "Approved",
      page: 1
    }).then(res => {}).catch(err => {});
  }
};